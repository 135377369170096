import type { NumberFormatOptions } from 'next-intl'
import { createFormatter } from 'next-intl'

import type { TimeSeriesDataType, TranslatedEnergyStrings } from '@/logged-in/utils/commonUtils'
import { convertToCsv, replaceHyphenWithMinus, setTZAndLocale } from '@/logged-in/utils/commonUtils'
import type { TimeSeriesData, TimeSeriesQueryOutputData } from '@/shared/graphql/queries/queryTypes'
import type { MeasurementTime, PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

import type { GranularityOption, LegendSeriesId } from './commons'
import {
  calculateEnergyTotals,
  getCostUnit,
  getTranslationKeyFromGranularityOption,
  resolutionTimeFormatter,
} from './commons'

export const checkTwoTimesMeasurement = (measurementTime?: MeasurementTime) =>
  measurementTime === 'TWO_TIME_MEASUREMENT_DAY_NIGHT' ||
  measurementTime === 'TWO_TIME_MEASUREMENT_SEASONAL'

export const generateConsumptionCSV = async (
  type: TimeSeriesDataType,
  data: TimeSeriesQueryOutputData,
  activeSeries: Record<LegendSeriesId, boolean>,
  activeGranularity: GranularityOption,
  locale: string,
  priceArea: PriceAreaCode,
  translatedStrings: TranslatedEnergyStrings,
  measurementTime?: MeasurementTime,
) => {
  const timeZone = getTimeZoneForPriceAreaCode(priceArea)
  const { number } = createFormatter({ locale })

  const period = getTranslationKeyFromGranularityOption(activeGranularity)

  const isTwoTimeMeasurement = checkTwoTimesMeasurement(measurementTime)

  const secondaryValueLabel =
    measurementTime === 'TWO_TIME_MEASUREMENT_SEASONAL'
      ? translatedStrings['consumptionOtherTime']
      : translatedStrings['consumptionNight']
  const primaryValueLabel =
    measurementTime === 'TWO_TIME_MEASUREMENT_SEASONAL'
      ? translatedStrings['consumptionWinter']
      : translatedStrings['consumptionDay']

  const getNightEnergy = (tsData: TimeSeriesData) =>
    isTwoTimeMeasurement
      ? {
          [`${secondaryValueLabel} [${data.measurementUnit}]`]: tsData.energy.find(
            (energy) => energy.type === 'NIGHT_ENERGY' || energy.type === 'OTHER_SEASON_ENERGY',
          )?.value
            ? number(
                tsData.energy.find(
                  (energy) =>
                    energy.type === 'NIGHT_ENERGY' || energy.type === 'OTHER_SEASON_ENERGY',
                )?.value || 0,
                {
                  maximumFractionDigits: 2,
                  useGrouping: false,
                },
              )
            : '--',
        }
      : {}

  const getDayEnergy = (tsData: TimeSeriesData) => {
    const hasSeasonalSecondaryValue = !!tsData.energy?.find(
      (energy) => energy.type === 'NIGHT_ENERGY' || energy.type === 'OTHER_SEASON_ENERGY',
    )

    const val =
      tsData?.energy?.find(
        (energy) =>
          energy.type === 'DAY_ENERGY' ||
          energy.type === 'WINTER_DAY_ENERGY' ||
          (!hasSeasonalSecondaryValue && energy.type === 'ENERGY'),
      )?.value ?? 0

    return {
      [`${type === 'Production' ? translatedStrings['production'] : isTwoTimeMeasurement ? primaryValueLabel : translatedStrings['consumption']} [${data.measurementUnit}]`]:
        val
          ? number(val, {
              maximumFractionDigits: 2,
              useGrouping: false,
            })
          : '--',
    }
  }

  const mappedData =
    data?.series.map((s) => ({
      [`${translatedStrings[period]}`]: resolutionTimeFormatter(
        activeGranularity,
        setTZAndLocale(s.atUTC, timeZone, locale),
        true,
      ),
      ...getDayEnergy(s),
      ...getNightEnergy(s),
      ...(activeSeries?.cost && {
        [`${type === 'Production' ? translatedStrings['revenue'] : translatedStrings['cost']} [${getCostUnit(data.costUnit, locale)}]`]:
          s.volumeBasedCost
            ? number(s.volumeBasedCost, {
                minimumFractionDigits: 2,
                useGrouping: false,
              })
            : '--',
      }),
      ...(activeSeries?.electricityPrice && {
        [`${translatedStrings['electricityPrice']} [${data.priceUnit}]`]: replaceHyphenWithMinus(
          s.price?.total
            ? number(s.price?.total, {
                minimumFractionDigits: 2,
                useGrouping: false,
              })
            : '--',
        ),
      }),
      ...(activeSeries?.temperature && {
        [`${translatedStrings['temperature']} [°C]`]: s.temperatureReading?.temperature
          ? replaceHyphenWithMinus(
              number(s.temperatureReading.temperature, {
                minimumFractionDigits: 2,
                useGrouping: false,
              }),
            )
          : '--',
      }),
    })) ?? []

  const totalValues = calculatedTotal(data.series, number, activeSeries, isTwoTimeMeasurement)

  const csvString = convertToCsv(type, mappedData, translatedStrings, totalValues)

  return csvString
}

const calculatedTotal = (
  series: TimeSeriesData[],
  number: (value: number | bigint, formatOrOptions?: string | NumberFormatOptions) => string,
  activeSeries: Record<LegendSeriesId, boolean>,
  isTwoTimeMeasurement = false,
) => {
  let totalConsumption: string | undefined,
    totalCost: string | undefined,
    totalConsumptionSecondary: string | undefined

  const consumptionTotals = calculateEnergyTotals(series, false, isTwoTimeMeasurement)

  if (!consumptionTotals) {
    return []
  }

  if (isNotNullOrUndefined(consumptionTotals.totalEnergy)) {
    totalConsumption = number(consumptionTotals.totalEnergy, {
      minimumFractionDigits: 2,
      useGrouping: false,
    })
  }

  if (isTwoTimeMeasurement && isNotNullOrUndefined(consumptionTotals.totalEnergyNight)) {
    totalConsumptionSecondary = number(consumptionTotals.totalEnergyNight, {
      minimumFractionDigits: 2,
      useGrouping: false,
    })
  }

  if (activeSeries.cost && isNotNullOrUndefined(consumptionTotals.totalCost)) {
    totalCost = number(consumptionTotals.totalCost, {
      minimumFractionDigits: 2,
      useGrouping: false,
    })
  }

  const totalValues = [totalConsumption, totalConsumptionSecondary, totalCost].filter(
    isNotNullOrUndefined,
  )

  return totalValues
}
