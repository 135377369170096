// eslint-disable-next-line no-restricted-syntax -- old one, can stay for now
'use server'

import type { TranslatedSpotPriceStrings } from '@/logged-in/utils/commonUtils'
import { getTranslatedStrings } from '@/logged-in/utils/commonUtils'
import type { PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'

import type { SpotEntry } from './commons'
import { generateSpotPriceCSV } from './utils'

const generateTranslatedSpotPriceCSV = async (
  data: SpotEntry[],
  averagePrice: { value: string; unit: string },
  currentLocale: string,
  priceArea: PriceAreaCode,
) => {
  if (!data) {
    return ''
  }
  const translatedStrings = (await getTranslatedStrings(
    'Spotprice',
    currentLocale,
  )) as TranslatedSpotPriceStrings
  const csvString = await generateSpotPriceCSV(
    data,
    averagePrice,
    currentLocale,
    priceArea,
    translatedStrings,
  )
  return csvString
}

export default generateTranslatedSpotPriceCSV
