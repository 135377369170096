import styled from 'styled-components'

import { ContentSwitcher as ElementalContentSwitcher, spacing, styles } from '@fortum/elemental-ui'

export const ContentSwitcher = styled(ElementalContentSwitcher)<{ isfullwidth?: string }>`
  & > div {
    height: 100%;
    max-width: ${(props) => (props.isfullwidth ? '100%' : 'auto')};

    > button {
      font-size: inherit;
      padding: ${spacing.xxxxs} ${spacing.xxs};
      flex: 1;
    }
  }

  ${styles.breakpoint.m} {
    max-width: ${(props) => (props.isfullwidth ? '' : 'min-content')};
  }

  ${styles.breakpoint.s} {
    max-width: ${(props) => (props.isfullwidth ? '' : 'min-content')};
    min-width: auto;
  }
` as typeof ElementalContentSwitcher
